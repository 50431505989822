import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/outline";
import classNames from '../../../utils/classNames';

export default function SelectMultiInput({ label, register, registerName, options, setValue, watch }) {

  const selectValues = watch(registerName) || [];

  const handleChange = (value) => {
    let updatedValues = value.flat(Infinity);

    //if two of the same value, remove both
    updatedValues = removeDuplicatesById(updatedValues);

    // Set the updated values
    setValue(registerName, updatedValues);
  };

  // Function to remove duplicates by ID
  function removeDuplicatesById(arr) {
    const idCount = new Map();

    // Count occurrences of each ID
    arr.forEach(obj => {
      idCount.set(obj.id, (idCount.get(obj.id) || 0) + 1);
    });

    // Filter out objects with duplicate IDs
    return arr.filter(obj => idCount.get(obj.id) === 1);
  }


  return (
    <div>
      <Listbox
        {...register(registerName)}
        value={selectValues}
        onChange={handleChange}
        multiple
      >
        {({ open }) => (
          <>
            <Listbox.Label className="block mb-2 text-sm font-medium text-gray-700">
              {label}
            </Listbox.Label>
            <div className="w-full relative">
              <Listbox.Button
                className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-etpink-500 focus:border-etpink-500 sm:text-sm"
              >
                <span className="block truncate">
                  {selectValues.length > 0
                    ? selectValues.map((v) => v.name).join(", ")
                    : label}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="h-5 w-5 text-gray-700"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                appear={true}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.id}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-etpink-600" : "text-gray-900",
                          "cursor-default select-none relative py-2 pl-8 pr-4"
                        )
                      }
                      value={option}
                    >
                      <>
                        <span
                          className={classNames(
                            selectValues.some((v) => v.id === option.id)
                              ? "font-semibold"
                              : "font-normal",
                            "block truncate"
                          )}
                        >
                          {option.name}
                        </span>

                        {selectValues.some((v) => v.id === option.id) && (
                          <span className="text-etpink-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                            <CheckIcon
                              className="h-5 w-5"
                              aria-hidden="true"
                            />
                          </span>
                        )}
                      </>
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}
