import { useEffect, useState } from "react";

import { Outlet } from "react-router-dom";

import { ChevronLeftIcon } from "@heroicons/react/solid";
import { AnnotationIcon, CashIcon, ChartPieIcon, CloudDownloadIcon, CollectionIcon, CurrencyPoundIcon, DocumentDuplicateIcon, DocumentSearchIcon, DocumentTextIcon, GlobeAltIcon, HeartIcon, HomeIcon, InboxInIcon, InformationCircleIcon, LibraryIcon, MailIcon, NewspaperIcon, PhotographIcon, PlusIcon, PresentationChartLineIcon, TemplateIcon, UserGroupIcon, UsersIcon } from "@heroicons/react/outline";

import NavBar from "./navBar";
import SidebarMobile from "./sideBarMobile";
import SidebarDesktop from "./sidebarDesktop";
import classNames from "../../utils/classNames";
import { withAgency } from "../../data/withAgency";
import ActivityFeed from "../activityFeed/activityFeed";
import FullscreenLoading from "../Shared/FullscreenLoading";
import useAgencyServiceToggles from '../../data/useAgencyServiceToggles';

function getNavItems(type, agency, toggles) {
  switch (type) {
    case "admin":
      return {
        general: [
          {
            name: "Dashboard",
            href: "/admin",
            icon: HomeIcon,
            end: true,
          },
          {
            name: "Agencies",
            href: "agencies",
            icon: LibraryIcon,
          },
          {
            name: "Groups",
            href: "groups",
            icon: UserGroupIcon,
          },
          {
            name: "Content Engine",
            href: "content-engine",
            icon: DocumentTextIcon,
          },
          {
            name: "C.E. Categories",
            href: "ce-categories",
            icon: PresentationChartLineIcon,
          },
          {
            name: "Media Manager",
            href: "media-manager",
            icon: PhotographIcon,
          },
          {
            name: "Website Policies",
            href: "policy-manager",
            icon: NewspaperIcon,
          },
          {
            name: "Changelogs",
            href: "changelogs",
            icon: InformationCircleIcon,
          },
        ],
        System: [
          {
            name: "Service Health",
            href: "servicehealth",
            icon: HeartIcon,
          },
          {
            name: "Email Templates (Work in Progress)",
            href: "emailtemplates",
            icon: MailIcon,
          },
        ],
      };
    default:
      let navItems = {};
      navItems = {
        general: [
          {
            name: "Dashboard",
            href: `/${agency.logicalName}`,
            icon: TemplateIcon,
            end: true,
          },
          {
            name: "My Leads",
            href: `/${agency.logicalName}/myleads`,
            icon: DocumentDuplicateIcon,
          }
        ],
        "Lead Generation": [
          {
            name: "Valuations",
            href: `/${agency.logicalName}/valuations`,
            icon: CurrencyPoundIcon,
            disabled: toggles?.valuationServiceToggle === false,
          },
          {
            name: "Viewing Requests",
            href: `/${agency.logicalName}/viewings`,
            icon: DocumentSearchIcon,
            disabled: toggles?.viewingServiceToggle === false,
          },
          {
            name: "Registrations",
            href: `/${agency.logicalName}/registrations`,
            icon: PlusIcon,
            disabled: toggles?.registrationServiceToggle === false,
          },
          {
            name: "General Enquiries",
            href: `/${agency.logicalName}/enquiries`,
            icon: InboxInIcon,
            disabled: toggles?.enquiryServiceToggle === false,
          },
          {
            name: "Mortgage Enquiries",
            href: `/${agency.logicalName}/mortgages`,
            icon: CashIcon,
            disabled: toggles?.mortgageServiceToggle === false,
          },
          {
            name: "Form Leads",
            href: `/${agency.logicalName}/formleads`,
            icon: CollectionIcon,
            disabled: toggles?.customFormsToggle === false,
          },
        ],
        "Website Content": [
          {
            name: "Team",
            href: `/${agency.logicalName}/team`,
            icon: UsersIcon,
          },
          {
            name: "Blog Posts",
            href: `/${agency.logicalName}/blog`,
            icon: DocumentTextIcon,
          },
          {
            name: "Reviews",
            href: `/${agency.logicalName}/reviews`,
            icon: AnnotationIcon,
          },
          {
            name: "Forms",
            href: `/${agency.logicalName}/forms`,
            icon: CollectionIcon,
            disabled: toggles?.customFormsToggle === false,
          },
          {
            name: "Landing Pages",
            href: `/${agency.logicalName}/landingpages`,
            icon: CollectionIcon,
            disabled: toggles?.customFormsToggle === false,
          }
          // {
          //   name: "Video Reviews",
          //   href: `/${agency.logicalName}/videoreviews`,
          //   icon: VideoCameraIcon,
          // },
          // {
          //   name: "Market Reports",
          //   href: `/${agency.logicalName}/marketreports`,
          //   icon: DocumentReportIcon,
          // },
        ],
      };

      if (toggles?.analyticsServiceToggle) {
        navItems["Analytics"] = [
          {
            name: "Properties",
            href: `/${agency.logicalName}/properties`,
            icon: HomeIcon,
          },
          {
            name: "Valuations",
            href: `/${agency.logicalName}/analytics/valuations`,
            icon: ChartPieIcon,
            disabled: toggles?.valuationServiceToggle === false,
          },
          {
            name: "Reviews",
            href: `/${agency.logicalName}/analytics/reviews`,
            icon: AnnotationIcon,
          },
          {
            name: "Portal Leads",
            href: `/${agency.logicalName}/analytics/portal`,
            icon: CloudDownloadIcon,
            disabled: toggles?.portalQualifierServiceToggle === false || toggles?.viewingServiceToggle === false,
          },
          {
            name: "Area Guides",
            href: `/${agency.logicalName}/analytics/areaguides`,
            icon: GlobeAltIcon,
          },
          // {
          //   name: "Email Marketing",
          //   href: `/${agency.logicalName}/analytics/emails`,
          //   icon: AtSymbolIcon,
          // },
          // {
          //   name: "Website",
          //   href: `/${agency.logicalName}/analytics/website`,
          //   icon: GlobeIcon,
          // },
        ];
      }

      return navItems;
  }
}

function _Dashboard({ type, agency, agencyReady }) {
  const { serviceToggles } = useAgencyServiceToggles(agency?.id);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [switchOpen, setSwitchOpen] = useState(false);
  const [feedOpen, setFeedOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [toggles, setToggles] = useState(null);
  const hideAgencyComponents = type === "admin" ? false : agency ? false : true;

  const navigation = hideAgencyComponents ? {} : getNavItems(type, agency, toggles);
  useEffect(() => {
    if (!serviceToggles.isLoading) {
      setToggles(serviceToggles.data);
      setIsLoading(false);
    }
  }, [agency, agencyReady, feedOpen, serviceToggles.isLoading, serviceToggles.data])

  if (!agencyReady || isLoading) {
    return <FullscreenLoading />;
  } else {
    return (
      <>
        <div className="h-full flex">
          <SidebarMobile
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
            switchOpen={switchOpen}
            setSwitchOpen={setSwitchOpen}
            navigation={navigation}
            onNavItemClick={() => setSidebarOpen(false)}
          />
          <SidebarDesktop navigation={navigation} />
          <div className="md:pl-56 flex flex-col flex-1">
            <NavBar setSidebarOpen={setSidebarOpen} />
            <div className="h-full flex">
              {/* <div className="flex flex-col min-w-0 flex-1 overflow-hidden">
                <div className="flex-1 relative z-0 flex overflow-hidden h-full"> */}
              <main className="flex-1 relative z-0 overflow-y-auto h-full">
                {/* Start main area*/}
                <div className="flex-grow py-6">
                  {/* {" "} */}
                  <Outlet />
                </div>

                {/* End main area */}
              </main>
              {Boolean(agency && type !== "admin") && (
                <>
                  <button
                    style={{ transform: `rotate(${!feedOpen ? "0" : "270"}deg)` }}
                    className="hidden xl:flex h-6 w-6 justify-center items-center sticky rounded-full bg-gray-50 border right-5 top-[4.5rem] z-5 transition-all duration-500"
                    onClick={() => setFeedOpen(!feedOpen)}
                  >
                    <ChevronLeftIcon className="h-5 w-5" />
                  </button>
                  <aside className={classNames(feedOpen ? "w-60" : "w-0", "hidden relative transition-all xl:flex xl:flex-col flex-shrink-0 overflow-y-auto h-full")}>
                    {/* Start secondary column (hidden on smaller screens) */}
                    <ActivityFeed />
                    {/* End secondary column */}
                  </aside>
                </>
              )}
              {/* </div>
              </div> */}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const Dashboard = withAgency(_Dashboard);

export default Dashboard;
