import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useChangelogs() {
  const staleTime = 1000 * 60 * 5; //5 minutes
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const changelogs = useQuery(
    ["changelogs"],
    () => get(`/Changelog`).then((res) => res.data),
    {
      staleTime: staleTime,
    }
  );

  const add = useMutation((changelog) => post(`/Changelog`, changelog), {
    onSuccess: () => {
      queryClient.invalidateQueries(["changelogs"]);
    },
  });

  const update = useMutation((changelog) => put(`/Changelog/${changelog.id}`, changelog), {
    onSuccess: () => {
      queryClient.invalidateQueries(["changelogs"]);
    },
  });

  const remove = useMutation((changelogId) => del(`/Changelog/${changelogId}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(["changelogs"]);
    },
  });

  const sendEmail = useMutation((date) => post(`/Changelog/send`, { date: date }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["changelogs"]);
    },
  });

  const sendTest = useMutation((date) => post(`/Changelog/test`, { date: date, emails: ["josh@simplecrumb.com", "directors@estatetrack.co.uk"] }), {
    onSuccess: () => {
      queryClient.invalidateQueries(["changelogs"]);
    },
  });

  return {
    changelogs,
    add,
    update,
    remove,
    sendEmail,
    sendTest
  };
}
