import { useEffect, useState } from "react";
import Modal from './../Shared/Modal';
import useChangelogs from "../../data/useChangelogs";
import { CheckIcon } from "@heroicons/react/outline";
import { ButtonInput } from "./../Shared/Inputs/ButtonInput";
import classNames from "classnames";
import ConfirmModal from "../Shared/ConfirmModal";


export default function ChangelogEmails({ open, setOpen }) {
  const { changelogs: allChangelogs, update, sendEmail, sendTest } = useChangelogs();
  const [changelogMonths, setChangelogMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [countSelected, setCountSelected] = useState(0);
  const [checkedStates, setCheckedStates] = useState({});


  const calculateCountSelected = () => {
    let count = 0;
    for (let changelog of changelogMonths[selectedMonth].changelogs) {
      if (!changelog.emailedOn && changelog.emailOn) {
        count++;
      }
    }
    setCountSelected(count);
  }

  const updateChangelog = (checked, changelog) => {
    changelog.emailOn = checked ? changelog.publishedOn : null;

    let categories = [];

    for(let i = 0; i < changelog.changelogCategories.length; i++) {
      categories.push(changelog.changelogCategories[i].changelogCategory);
    }

    const updatedChangelog = { 
      ...changelog, 
      emailOn: checked ? changelog.publishedOn : null,
      changelogCategories: categories
    };

    update.mutate(updatedChangelog, {
      onSuccess: () => {
        console.log("Changelog updated");
        calculateCountSelected();
      },
      onError: (error) => {
        console.error("Error updating changelog:", error);
      },
    });

  }

  const manuallySendEmail = () => {
    sendEmail.mutate(changelogMonths[selectedMonth].lastDay);
    setConfirm(false);
  }

  const sendTestEmail = () => {
    sendTest.mutate(changelogMonths[selectedMonth].lastDay);
  }


  useEffect(() => {
    if (!allChangelogs.isLoading) {
      // Group changelogs by month and year
      const months = allChangelogs.data.reduce((acc, changelog) => {
        if (!changelog.publishedOn) return acc;
  
        const date = new Date(changelog.publishedOn);
        const month = date.toLocaleString("default", { month: "long" });
        const year = date.getFullYear();
        const key = `${month}-${year}`;

        // Find or create an entry for this month-year combination
        if (!acc[key]) {
          // last day of the published on month
          const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

          acc[key] = { month, year, key, lastDay, changelogs: [] };
        }
  
        // Add the changelog to the correct month-year group
        acc[key].changelogs.push(changelog);
  
        return acc;
      }, {});
  
      // Convert the object into an array
      const monthList = Object.values(months);
      console.log("Month list", monthList);
  
      setChangelogMonths(monthList);

      // Initialize checked states
      const initialCheckedStates = {};
      monthList.forEach((month) => {
        month.changelogs.forEach((changelog) => {
          initialCheckedStates[changelog.id] = changelog.emailOn ? true : false;
        });
      });
      setCheckedStates(initialCheckedStates);
    }
  }, [allChangelogs.isLoading, allChangelogs.data]);
  
  useEffect(() => {
    if (changelogMonths[selectedMonth]) {
      calculateCountSelected();
    }
  }, [selectedMonth, changelogMonths]);

  const handleCheckboxChange = (changelogId, checked) => {
    setCheckedStates((prevState) => ({
      ...prevState,
      [changelogId]: checked,
    }));
    const changelog = changelogMonths[selectedMonth].changelogs.find(
      (changelog) => changelog.id === changelogId
    );
    console.log("changelogs", changelogMonths[selectedMonth].changelogs);
    console.log(changelog);
    updateChangelog(checked, changelog);
  };

  return (
    <>
      <ConfirmModal title={"Send Changelog Email"} message={`Are you sure you want to send this changelog email? \n This will send to everyone in the system!`} confirmButtonText={"Confirm"} open={confirm} setOpen={setConfirm} onConfirm={manuallySendEmail} />
      <Modal open={open} setOpen={setOpen} width={"max-w-7xl"}>
        <div className="w-full pb-6 flex justify-between">
          <ButtonInput label={`Test Send ${countSelected} ${changelogMonths[selectedMonth]?.month}`} isSubmit={false} onClick={() => sendTestEmail()} />
          <ButtonInput label={`Manually Send ${countSelected} ${changelogMonths[selectedMonth]?.month}`} isSubmit={false} onClick={() => setConfirm(true)} />
        </div>
        <div className="w-full flex">
          <aside className="flex flex-col w-48 border-r">
            {changelogMonths.map((month, index) => (
              <div key={month.year + month.month}>
                <h2 className={`text-lg font-semibold cursor-pointer transition-colors duration-300 px-2 py-2
                  hover:bg-gray-50 ${selectedMonth == index ? "text-etpink-700" : "text-gray-600 hover:text-gray-900" }`}
                  onClick={() => setSelectedMonth(index)}>
                  {month.month} {month.year}  
                </h2>
              </div>
            ))  
            }
          </aside>

          <div className="grid grid-cols-4">
            
            {changelogMonths[selectedMonth] && changelogMonths[selectedMonth].changelogs.map((changelog) => (
              <div key={changelog.id} className="w-full p-4">
                <label style={{ opacity : changelog.emailedOn ? 0.5 : 1 }} className={classNames(changelog.emailedOn ? "opacity-50" : "hover:bg-gray-50 cursor-pointer", `bg-white shadow-md rounded-lg p-4 flex h-full`)}>
                  <div className="grow">
                    <h3 className="text-lg font-semibold">{changelog.title}</h3>
                    <small className="text-gray-500">{new Date(changelog.publishedOn).toLocaleDateString()}</small>
                  </div>
                  {changelog.emailedOn ? (
                    <div className="flex items-end">
                      <CheckIcon className="w-6 h-6 text-green-600" />
                    </div>
                  ) : (
                    <div className="flex items-end">
                      <input
                        type="checkbox"
                        className="h-4 w-4 text-etpink-600 focus:ring-etpink-500 border-gray-300 rounded"
                        checked={checkedStates[changelog.id] || false}
                        onChange={(e) => handleCheckboxChange(changelog.id, e.target.checked)}
                      />
                    </div>
                  )}
                </label>
              </div>    
            ))}
          </div>

        </div>
      </Modal>
    </>
    
  )
}