import { useMutation, useQuery, useQueryClient } from "react-query";
import useApiHelper from "../services/useApiHelper";

export default function useChangelogSingle(changelogId) {
  const staleTime = 1000 * 60 * 5; //5 minutes
  const queryClient = useQueryClient();
  const { get, post, put, del } = useApiHelper();

  const changelog = useQuery(
    ["changelogs", changelogId],
    () => get(`/Changelog/${changelogId}`).then((res) => res.data),
    {
      staleTime: staleTime,
      enabled: !!changelogId,
    }
  );

  const add = useMutation((changelog) => post(`/Changelog`, changelog), {
    onSuccess: () => {
      queryClient.invalidateQueries(["changelogs"]);
    },
  });

  const update = useMutation((changelog) => put(`/Changelog/${changelogId}`, changelog), {
    onSuccess: () => {
      queryClient.invalidateQueries(["changelogs"]);
    },
  });

  const remove = useMutation((changelogId) => del(`/Changelog/${changelogId}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(["changelogs"]);
    },
  });

  return {
    changelog,
    add,
    update,
    remove,
  };
}
