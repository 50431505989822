import { useEffect, useState } from "react";

import { set, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import Breadcrumbs from "../../../components/Shared/Breadcrumbs";
import { ButtonInput } from "../../../components/Shared/Inputs/ButtonInput";
import { TextInput } from "../../../components/Shared/Inputs/TextInput";
import LoadingWheel from "../../../components/Shared/LoadingWheel";
import useChangelogSingle from "../../../data/useChangelogsSingle";
import useDefaultCRUDHandlers from "../../../hooks/useDefaultCRUDHandlers";
import useChangelogCategories from "../../../data/useChangelogCategories";
import SelectMultiInput from "../../../components/Shared/Inputs/SelectMultiInput";
import CustomJoditEditor from "../../../components/Shared/CustomJoditEditor";


export default function ChangelogAddEdit() {
  const queryClient = useQueryClient();
  const { changelogId } = useParams();
  const { changelog: changelogSingle, add, update } = useChangelogSingle(changelogId);
  const { changelogCategories } = useChangelogCategories();
  const navigate = useNavigate();
  const { register, getValues, setValue, watch, handleSubmit, reset, control } =
    useForm({
      defaultValues: {
        title: "",
        version: "",
        description: "",
        publishedOn: new Date().toISOString().slice(0, 16),
        emailedOn: null,
      },
    });
  const { saveHandlers } = useDefaultCRUDHandlers("Changelog");
  const [isLoading, setIsLoading] = useState(true);
  const [changelog, setChangelog] = useState(null);
  const [content, setContent] = useState("");
  const [isPublish, setIsPublish] = useState(false);


  const onSubmit = (data) => {
    setIsLoading(true);

    var newChangelog = {
      ...changelog,
      ...data,
      description: content,
      publishedOn: isPublish ? data.publishedOn : null,
    };

    if (changelogId) {
      update.mutate(newChangelog,
        {
          ...saveHandlers,
          onSettled: () => setIsLoading(false)
        }
      );
    } else {
      add.mutate(newChangelog,
        {
          onSuccess: () => {
            reset();
            navigate(`/admin/changelogs`);
            setIsLoading(false);
            saveHandlers.onSuccess();
          },
          onError: () => {
            saveHandlers.onError();
            setIsLoading(false);
          }
        }
      );
    }

    reset({
      ...data,
    });

    setChangelog(newChangelog);
  };


  useEffect(() => {
    if (!changelogSingle.isLoading && !changelogCategories.isLoading) {
      if (changelogId) {
        setContent(changelogSingle.data.description);
        setChangelog(changelogSingle.data);

        let categories = changelogSingle.data.changelogCategories.map((category) => {
          return category.changelogCategory;
        });

        reset({ ...changelogSingle.data, changelogCategories: categories });
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    }
  }, [
    changelogSingle.isLoading,
    changelogCategories.isLoading,
    changelogSingle.data,
    changelogCategories.data,
    changelogId,
  ]);

  const pages = [
    { to: "..", label: "Changelogs", current: false },
    {
      to: changelogId && changelogSingle.data ? `${changelogId}` : "",
      label: changelogId ? `${getValues("title")}` : "New Changelog",
      current: true,
    },
  ];

  if (isLoading || changelogSingle.isLoading) {
    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="h-80 max-w-7xl flex items-center justify-center">
          <LoadingWheel width="w-12" height="h-12" />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="px-4 sm:px-6 lg:px-8">
          <Breadcrumbs pages={pages} />

          <div className="flex justiy-between">
            <div className="sm:flex-auto my-4">
              <h3 className="text-xl font-semibold text-gray-900">
                {changelogId ? <span dangerouslySetInnerHTML={{ __html: getValues("title") }}></span> : "New Changelog"}
              </h3>
              <p className="mt-2 text-sm text-gray-700">
                {changelogId
                  ? <>View and edit <span dangerouslySetInnerHTML={{ __html: getValues("title") }}></span></>
                  : "Create a new changelog entry"}
              </p>
            </div>

          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-1 gap-2 lg:grid-cols-4">

              {/* Left hand column */}
              <div className="grid grid-cols-2 gap-4 col-span-1 lg:pr-10 lg:col-span-3">

                <TextInput label={"Title"} register={register} registerName={"title"} cols="col-span-2" />

                {/* <TextInput label={"Version"} register={register} registerName={"version"} /> */}

                <SelectMultiInput
                  label="Category"
                  register={register}
                  watch={watch}
                  setValue={setValue}
                  registerName={"changelogCategories"}
                  options={changelogCategories.data}
                />

                <div>
                  <label className="mb-2 block text-sm font-medium text-gray-700">
                    Published On
                  </label>
                  <input {...register("publishedOn")}
                    step="60"
                    type="datetime-local"
                    className="shadow-sm focus:ring-etpink-400 focus:border-etpink-400 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-2 mt-4">
                  <label className="block mb-1 text-sm font-medium text-gray-700">
                    Description
                  </label>
                  <CustomJoditEditor
                    content={content}
                    setContent={setContent}
                    height={350}
                  />
                </div>

              </div>

              {/* Right hand column */}
              <div className="mt-7 flex flex-col gap-4">
                <ButtonInput isSubmit={true} onClick={() => setIsPublish(true)} label={"Publish"} />
                <ButtonInput isSubmit={true} onClick={() => setIsPublish(false)} label={"Save as Draft"} />
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
